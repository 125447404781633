/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const HeroiconsMiniCursorArrowRipple = ({ className }) => {
  return (
    <svg
      className={`heroicons-mini-cursor-arrow-ripple ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M6.11099 11.8891C3.96311 9.7412 3.96311 6.2588 6.11099 4.11091C8.25887 1.96303 11.7413 1.96303 13.8892 4.11091C14.9633 5.185 15.5001 6.59127 15.5001 8C15.5001 8.41421 15.8359 8.75 16.2501 8.75C16.6643 8.75 17.0001 8.41421 17.0001 8C17.0001 6.2097 16.3165 4.41694 14.9498 3.05025C12.2162 0.316582 7.784 0.316582 5.05033 3.05025C2.31666 5.78392 2.31666 10.2161 5.05033 12.9497C5.34322 13.2426 5.8181 13.2426 6.11099 12.9497C6.40388 12.6569 6.40388 12.182 6.11099 11.8891Z"
        fill="#7041F1"
      />
      <path
        className="path"
        d="M8.23234 6.23223C7.25603 7.20854 7.25603 8.79146 8.23234 9.76777C8.52524 10.0607 8.52524 10.5355 8.23234 10.8284C7.93945 11.1213 7.46458 11.1213 7.17168 10.8284C5.60959 9.26633 5.60959 6.73367 7.17168 5.17157C8.73378 3.60948 11.2664 3.60948 12.8285 5.17157C13.6094 5.95247 14.0001 6.97747 14.0001 8C14.0001 8.41421 13.6643 8.75 13.2501 8.75C12.8359 8.75 12.5001 8.41421 12.5001 8C12.5001 7.35903 12.2562 6.72054 11.7679 6.23223C10.7916 5.25592 9.20866 5.25592 8.23234 6.23223Z"
        fill="#7041F1"
      />
      <path
        className="path"
        d="M10.7657 7.51062C10.5871 7.24492 10.2596 7.12184 9.95029 7.20417C9.64094 7.2865 9.41795 7.5561 9.39509 7.8754L8.90409 14.7363C8.88302 15.0306 9.03649 15.3099 9.29622 15.4499C9.55594 15.59 9.87364 15.5647 10.108 15.3854L11.1508 14.5873L12.1363 18.2653C12.2435 18.6654 12.6548 18.9028 13.0549 18.7956C13.455 18.6884 13.6924 18.2771 13.5852 17.877L12.6083 14.2312L13.9005 14.4349C14.1951 14.4814 14.4893 14.3489 14.6497 14.0974C14.81 13.846 14.8062 13.5233 14.6398 13.2758L10.7657 7.51062Z"
        fill="#7041F1"
      />
    </svg>
  );
};
