/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const HeroiconsSolidScale = ({ className }) => {
  return (
    <svg
      className={`heroicons-solid-scale ${className}`}
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        clipRule="evenodd"
        d="M12 2.25C12.4142 2.25 12.75 2.58579 12.75 3V3.75565C14.8183 3.78685 16.8558 3.94691 18.8546 4.22821C19.8808 4.37264 20.8968 4.54903 21.9016 4.75635C22.2784 4.83409 22.5352 5.18431 22.4962 5.56704C22.4571 5.94977 22.1348 6.24087 21.75 6.24087H19.8324L22.306 16.3654C22.3872 16.6979 22.2331 17.0431 21.9313 17.2046C20.9827 17.7124 19.8988 18 18.75 18C17.6012 18 16.5174 17.7124 15.5688 17.2046C15.267 17.0431 15.1129 16.6979 15.1941 16.3654L17.6677 6.24087H12.75V19.5217C14.0425 19.5968 15.2843 19.8645 16.4466 20.2971C16.7867 20.4237 16.9874 20.7757 16.9231 21.1329C16.8588 21.4901 16.5479 21.75 16.185 21.75H7.81465C7.4517 21.75 7.14083 21.4901 7.07652 21.1329C7.0122 20.7757 7.2129 20.4237 7.55305 20.2971C8.71543 19.8645 9.9574 19.5967 11.25 19.5217V6.24087H6.33238L8.80598 16.3654C8.88721 16.6979 8.73307 17.0431 8.43134 17.2046C7.48271 17.7124 6.39885 18 5.25004 18C4.10124 18 3.01738 17.7124 2.06875 17.2046C1.76702 17.0431 1.61288 16.6979 1.69411 16.3654L4.16771 6.24087H2.25004C1.86532 6.24087 1.54299 5.94977 1.50392 5.56704C1.46485 5.18431 1.7217 4.83409 2.09849 4.75635C3.10325 4.54903 4.11928 4.37264 5.14552 4.22821C7.14429 3.94691 9.18169 3.78686 11.25 3.75565V3C11.25 2.58579 11.5858 2.25 12 2.25ZM16.878 15.7934L18.75 8.13096L20.6221 15.7934H16.878ZM7.12211 15.7934L5.25004 8.13096L3.37797 15.7934H7.12211Z"
        fill="#7041F1"
        fillRule="evenodd"
      />
    </svg>
  );
};
