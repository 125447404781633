/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const HeroiconsSolidFingerPrint = ({ className }) => {
  return (
    <svg
      className={`heroicons-solid-finger-print ${className}`}
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        clipRule="evenodd"
        d="M12 3.75C10.6232 3.75 9.34469 4.16143 8.27806 4.86788C7.93272 5.09661 7.46735 5.00207 7.23862 4.65674C7.0099 4.3114 7.10443 3.84603 7.44977 3.61731C8.75443 2.7532 10.3196 2.25 12 2.25C16.5563 2.25 20.25 5.94365 20.25 10.5C20.25 13.5153 19.6759 16.3983 18.6299 19.0442C18.4776 19.4294 18.0419 19.6182 17.6567 19.466C17.2715 19.3137 17.0827 18.8779 17.2349 18.4927C18.2127 16.0196 18.75 13.3235 18.75 10.5C18.75 6.77208 15.7279 3.75 12 3.75ZM6.15674 5.73863C6.50207 5.96735 6.59661 6.43272 6.36788 6.77806C5.66143 7.84469 5.25 9.12316 5.25 10.5C5.25 12.1132 4.78655 13.6204 3.98461 14.893C3.76378 15.2434 3.30067 15.3485 2.95024 15.1276C2.5998 14.9068 2.49474 14.4437 2.71558 14.0933C3.37094 13.0533 3.75 11.8222 3.75 10.5C3.75 8.81959 4.2532 7.25443 5.1173 5.94977C5.34603 5.60444 5.8114 5.5099 6.15674 5.73863ZM12 7.5C10.3431 7.5 9 8.84315 9 10.5C9 13.5997 7.82403 16.4266 5.89464 18.5556C5.61649 18.8626 5.14219 18.8859 4.83526 18.6077C4.52833 18.3296 4.50499 17.8553 4.78314 17.5484C6.4722 15.6845 7.5 13.2132 7.5 10.5C7.5 8.01472 9.51472 6 12 6C14.4853 6 16.5 8.01472 16.5 10.5C16.5 11.0472 16.4778 11.5897 16.4334 12.1264C16.3993 12.5392 16.037 12.8462 15.6242 12.8121C15.2114 12.778 14.9044 12.4157 14.9385 12.0029C14.9794 11.5078 15 11.0066 15 10.5C15 8.84315 13.6569 7.5 12 7.5ZM12.0003 9.75C12.4145 9.75 12.7503 10.0858 12.7503 10.5C12.7503 14.4082 11.3259 17.9855 8.96887 20.7384C8.69947 21.053 8.22601 21.0897 7.91138 20.8203C7.59674 20.5509 7.56006 20.0775 7.82946 19.7628C9.9626 17.2715 11.2503 14.0371 11.2503 10.5C11.2503 10.0858 11.5861 9.75 12.0003 9.75ZM15.2387 14.9331C15.6371 15.0467 15.8678 15.4617 15.7542 15.8601C15.1843 17.8576 14.3041 19.7239 13.1694 21.4035C12.9375 21.7467 12.4713 21.837 12.128 21.6051C11.7848 21.3732 11.6946 20.907 11.9264 20.5637C12.974 19.0132 13.7861 17.291 14.3117 15.4485C14.4254 15.0502 14.8404 14.8194 15.2387 14.9331Z"
        fill="#7041F1"
        fillRule="evenodd"
      />
    </svg>
  );
};
