/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const HeroiconsSolidGlobeAlt = ({ className }) => {
  return (
    <svg
      className={`heroicons-solid-globe-alt ${className}`}
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M21.7214 12.7518C21.7404 12.5037 21.75 12.253 21.75 12.0001C21.75 10.4759 21.4003 9.0334 20.7767 7.74847C19.5396 8.92282 18.0671 9.85158 16.4374 10.4567C16.4789 10.9656 16.5 11.4804 16.5 12.0001C16.5 13.1012 16.4051 14.1803 16.2229 15.2294C18.2163 14.7278 20.0717 13.8793 21.7214 12.7518Z"
        fill="#7041F1"
      />
      <path
        className="path"
        d="M14.6343 15.5502C14.874 14.4045 15 13.217 15 12.0001C15 11.6317 14.9885 11.266 14.9657 10.9033C14.0141 11.13 13.021 11.2501 12 11.2501C10.979 11.2501 9.98594 11.13 9.0343 10.9033C9.01155 11.266 9 11.6317 9 12.0001C9 13.217 9.12601 14.4045 9.3657 15.5502C10.2246 15.6818 11.1043 15.7501 12 15.7501C12.8957 15.7501 13.7754 15.6818 14.6343 15.5502Z"
        fill="#7041F1"
      />
      <path
        className="path"
        d="M9.77224 17.1191C10.5028 17.2056 11.2462 17.2501 12 17.2501C12.7538 17.2501 13.4972 17.2056 14.2278 17.1191C13.714 18.7747 12.9575 20.3236 12 21.7241C11.0425 20.3236 10.286 18.7747 9.77224 17.1191Z"
        fill="#7041F1"
      />
      <path
        className="path"
        d="M7.77705 15.2294C7.59493 14.1803 7.5 13.1012 7.5 12.0001C7.5 11.4804 7.52114 10.9656 7.56261 10.4567C5.93286 9.85158 4.46039 8.92282 3.22333 7.74847C2.59973 9.0334 2.25 10.4759 2.25 12.0001C2.25 12.253 2.25964 12.5037 2.27856 12.7518C3.92826 13.8793 5.78374 14.7278 7.77705 15.2294Z"
        fill="#7041F1"
      />
      <path
        className="path"
        d="M21.3561 14.7526C20.3404 18.2105 17.4597 20.8706 13.8776 21.5694C14.744 20.1124 15.4185 18.528 15.8664 16.851C17.8263 16.4401 19.6736 15.7232 21.3561 14.7526Z"
        fill="#7041F1"
      />
      <path
        className="path"
        d="M2.64395 14.7526C4.32642 15.7232 6.17372 16.4401 8.13356 16.851C8.58146 18.528 9.25602 20.1124 10.1224 21.5694C6.54027 20.8706 3.65964 18.2105 2.64395 14.7526Z"
        fill="#7041F1"
      />
      <path
        className="path"
        d="M13.8776 2.43068C16.3991 2.92257 18.5731 4.38632 19.9937 6.41611C18.9351 7.48496 17.6637 8.34263 16.2483 8.92029C15.862 6.58294 15.0435 4.39145 13.8776 2.43068Z"
        fill="#7041F1"
      />
      <path
        className="path"
        d="M12 2.276C13.4287 4.3656 14.4097 6.78549 14.805 9.39756C13.9083 9.62768 12.9684 9.75005 12 9.75005C11.0316 9.75005 10.0917 9.62768 9.19503 9.39756C9.5903 6.78549 10.5713 4.3656 12 2.276Z"
        fill="#7041F1"
      />
      <path
        className="path"
        d="M10.1224 2.43067C8.95648 4.39144 8.13795 6.58294 7.75171 8.92029C6.33629 8.34263 5.06489 7.48496 4.00635 6.41611C5.42689 4.38632 7.60085 2.92257 10.1224 2.43067Z"
        fill="#7041F1"
      />
    </svg>
  );
};
