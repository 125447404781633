/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const HeroiconsMiniArrowUpOnSquare = ({ className }) => {
  return (
    <svg
      className={`heroicons-mini-arrow-up-on-square ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        clipRule="evenodd"
        d="M13.75 7H10.75L10.75 3.6599L12.7004 5.76034C12.9823 6.06387 13.4568 6.08145 13.7603 5.7996C14.0639 5.51774 14.0814 5.04319 13.7996 4.73966L10.5496 1.23966C10.4077 1.08684 10.2085 1 10 1C9.79145 1 9.59231 1.08684 9.4504 1.23966L6.2004 4.73966C5.91855 5.04319 5.93613 5.51774 6.23966 5.79959C6.54319 6.08145 7.01774 6.06387 7.29959 5.76034L9.25 3.6599L9.25 7H6.25C5.00736 7 4 8.00736 4 9.25V16.75C4 17.9926 5.00736 19 6.25 19H13.75C14.9926 19 16 17.9926 16 16.75V9.25C16 8.00736 14.9926 7 13.75 7ZM10.75 7H9.25L9.25 12.25C9.25 12.6642 9.58579 13 10 13C10.4142 13 10.75 12.6642 10.75 12.25L10.75 7Z"
        fill="#7041F1"
        fillRule="evenodd"
      />
    </svg>
  );
};
