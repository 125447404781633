/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const HeroiconsMiniChartPie = ({ className }) => {
  return (
    <svg
      className={`heroicons-mini-chart-pie ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M12 9C11.4477 9 11 8.55229 11 8V3C11 2.44772 11.4503 1.9925 11.997 2.07047C15.0651 2.50798 17.492 4.93489 17.9295 8.00297C18.0075 8.54972 17.5523 9 17 9H12Z"
        fill="#7041F1"
      />
      <path
        className="path"
        d="M8.00297 4.07047C8.54972 3.9925 9 4.44772 9 5V10C9 10.5523 9.44772 11 10 11H15C15.5523 11 16.0075 11.4503 15.9295 11.997C15.4456 15.3908 12.5275 18 9 18C5.13401 18 2 14.866 2 11C2 7.4725 4.60923 4.55442 8.00297 4.07047Z"
        fill="#7041F1"
      />
    </svg>
  );
};
