/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const HeroiconsMiniCodeBracket = ({ className }) => {
  return (
    <svg
      className={`heroicons-mini-code-bracket ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        clipRule="evenodd"
        d="M6.28033 5.21967C6.57322 5.51256 6.57322 5.98744 6.28033 6.28033L2.56066 10L6.28033 13.7197C6.57322 14.0126 6.57322 14.4874 6.28033 14.7803C5.98744 15.0732 5.51256 15.0732 5.21967 14.7803L0.96967 10.5303C0.676777 10.2374 0.676777 9.76256 0.96967 9.46967L5.21967 5.21967C5.51256 4.92678 5.98744 4.92678 6.28033 5.21967ZM13.7197 5.21967C14.0126 4.92678 14.4874 4.92678 14.7803 5.21967L19.0303 9.46967C19.3232 9.76256 19.3232 10.2374 19.0303 10.5303L14.7803 14.7803C14.4874 15.0732 14.0126 15.0732 13.7197 14.7803C13.4268 14.4874 13.4268 14.0126 13.7197 13.7197L17.4393 10L13.7197 6.28033C13.4268 5.98744 13.4268 5.51256 13.7197 5.21967Z"
        fill="#7041F1"
        fillRule="evenodd"
      />
      <path
        className="path"
        clipRule="evenodd"
        d="M11.3774 2.0109C11.7856 2.08128 12.0595 2.46924 11.9891 2.87743L9.48909 17.3774C9.41872 17.7856 9.03076 18.0595 8.62257 17.9891C8.21438 17.9187 7.94053 17.5308 8.0109 17.1226L10.5109 2.62257C10.5813 2.21438 10.9692 1.94053 11.3774 2.0109Z"
        fill="#7041F1"
        fillRule="evenodd"
      />
    </svg>
  );
};
