/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const HeroiconsSolidEnvelopeOpen = ({ className }) => {
  return (
    <svg
      className={`heroicons-solid-envelope-open ${className}`}
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M19.5 22.5C21.1569 22.5 22.5 21.1569 22.5 19.5V11.3262L15.6212 15.3481L19.1056 17.2243C19.4703 17.4207 19.6067 17.8755 19.4104 18.2402C19.214 18.6049 18.7591 18.7414 18.3944 18.545L12.7112 15.4848C12.2672 15.2457 11.7328 15.2457 11.2889 15.4848L5.60558 18.545C5.24087 18.7414 4.78603 18.6049 4.58965 18.2402C4.39327 17.8755 4.52972 17.4207 4.89442 17.2243L8.37878 15.3481L1.5 11.3262V19.5C1.5 21.1569 2.84315 22.5 4.5 22.5L19.5 22.5Z"
        fill="#7041F1"
      />
      <path
        className="path"
        d="M1.5 9.58864V8.84391C1.5 7.74026 2.10597 6.72574 3.0777 6.2025L10.5777 2.16404C11.4656 1.68592 12.5344 1.68592 13.4223 2.16404L20.9223 6.2025C21.894 6.72574 22.5 7.74027 22.5 8.84391V9.58864L14.0742 14.5151L13.4223 14.1641C12.5344 13.686 11.4656 13.6859 10.5777 14.1641L9.92585 14.5151L1.5 9.58864Z"
        fill="#7041F1"
      />
    </svg>
  );
};
