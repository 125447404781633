/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const IndivIConsSolidInstagram = ({ className }) => {
  return (
     <svg
      className={`heroicons-solid-x-mark ${className}`}
      fill="none" 
      height="24"
      viewBox="0 0 17 16"
      width="24"
      xmlns="http://www.w3.org/2000/svg">
      <path className="path" clipRule="evenodd" fillRule="evenodd" fill="none" d="M12.5251 0H4.18069C1.87541 0 0 1.87596 0 4.18193V11.8181C0 14.124 1.87541 16 4.18069 16H12.5251C14.8304 16 16.7058 14.124 16.7058 11.8181V4.18193C16.7058 1.87596 14.8304 0 12.5251 0ZM1.47482 4.18193C1.47482 2.68966 2.68887 1.47525 4.18069 1.47525H12.5251C14.0169 1.47525 15.231 2.68966 15.231 4.18193V11.8181C15.231 13.3103 14.0169 14.5248 12.5251 14.5248H4.18069C2.68887 14.5248 1.47482 13.3103 1.47482 11.8181V4.18193Z"/>
      <path className="path" clipRule="evenodd" fillRule="evenodd" fill="none" d="M8.3536 11.8887C10.4973 11.8887 12.2424 10.1441 12.2424 7.99878C12.2424 5.85348 10.4983 4.10889 8.3536 4.10889C6.20893 4.10889 4.46484 5.85348 4.46484 7.99878C4.46484 10.1441 6.20893 11.8887 8.3536 11.8887ZM8.3536 5.58508C9.68481 5.58508 10.7675 6.66813 10.7675 7.99973C10.7675 9.33133 9.68481 10.4144 8.3536 10.4144C7.02239 10.4144 5.93966 9.33133 5.93966 7.99973C5.93966 6.66813 7.02239 5.58508 8.3536 5.58508Z" />
      <path className="path" clipRule="evenodd" fillRule="evenodd" fill="none" d="M12.6015 4.73728C13.1787 4.73728 13.6493 4.26758 13.6493 3.6892C13.6493 3.11081 13.1797 2.64111 12.6015 2.64111C12.0233 2.64111 11.5537 3.11081 11.5537 3.6892C11.5537 4.26758 12.0233 4.73728 12.6015 4.73728Z" />
    </svg>
  );
};

