/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const IndivIConsSolidTwitter = ({ className }) => {
  return (
     <svg
      className={`heroicons-solid-x-mark ${className}`}
      fill="none" 
      height="24"
      viewBox="0 0 13 13"
      width="24"
      xmlns="http://www.w3.org/2000/svg">
      <path className="path" clipRule="evenodd" fillRule="evenodd" fill="none" d="M0.0312673 0L5.05039 6.71244L0 12.1701H1.13697L5.55901 7.39207L9.13157 12.1701H13L7.69873 5.0801L12.3999 0H11.2629L7.19087 4.40046L3.90046 0H0.0320213H0.0312673ZM1.70278 0.837528H3.47953L11.327 11.3326H9.55021L1.70278 0.837528Z"/>
    </svg>
  );
};
