/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const HeroiconsMiniHandThumbDown = ({ className }) => {
  return (
    <svg
      className={`heroicons-mini-hand-thumb-down ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M18.9051 12.75C18.9051 13.4404 18.3454 14 17.6551 14C16.9647 14 16.4051 13.4404 16.4051 12.75L16.4051 5.25C16.4051 4.55964 16.9647 4 17.6551 4C18.3454 4 18.9051 4.55964 18.9051 5.25L18.9051 12.75Z"
        fill="#7041F1"
      />
      <path
        className="path"
        d="M8.90506 17L8.90506 18.2998C8.90506 18.5676 8.76532 18.8259 8.51012 18.9068C8.31924 18.9674 8.11596 19 7.90506 19C6.80049 19 5.90506 18.1046 5.90506 17C5.90506 16.005 6.08672 15.0524 6.41869 14.1735C6.62281 13.6332 6.25265 13 5.67502 13L3.15506 13C1.91242 13 0.893971 11.9904 1.00885 10.7531C1.20195 8.67339 1.6604 6.67064 2.35003 4.77906C2.75162 3.67752 3.83276 3 5.00522 3L8.19686 3C8.66259 3 9.12193 3.10843 9.5385 3.31672L12.2716 4.68328C12.6882 4.89156 13.1475 5 13.6133 5L14.9051 5L14.9051 12L13.9419 12C13.2574 12 12.684 12.4825 12.3297 13.0681C11.8412 13.8752 11.0754 14.4958 10.1645 14.7977C9.73167 14.9412 9.31084 15.184 9.15258 15.6116C8.9925 16.0441 8.90506 16.5119 8.90506 17Z"
        fill="#7041F1"
      />
    </svg>
  );
};
