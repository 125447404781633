/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const DownArrowOutline = ({ className }) => {
  return (
    <svg
      className={`heroicons-mini-adjustments-horizontal ${className}`}
      fill="none"
      height="8"
      viewBox="0 0 14 8"
      width="14"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M1 1L7 7L13 1" stroke="#7041F1" className='path'/>
    </svg>
  );
};
