/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const HeroiconsMiniWindow = ({ className }) => {
  return (
    <svg
      className={`heroicons-mini-window ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        clipRule="evenodd"
        d="M3.25 3C2.00736 3 1 4.00736 1 5.25V14.75C1 15.9926 2.00736 17 3.25 17H16.75C17.9926 17 19 15.9926 19 14.75V5.25C19 4.00736 17.9926 3 16.75 3H3.25ZM2.5 9V14.75C2.5 15.1642 2.83579 15.5 3.25 15.5H16.75C17.1642 15.5 17.5 15.1642 17.5 14.75V9H2.5ZM4 5.25C3.58579 5.25 3.25 5.58579 3.25 6V6.01C3.25 6.42421 3.58579 6.76 4 6.76H4.01C4.42421 6.76 4.76 6.42421 4.76 6.01V6C4.76 5.58579 4.42421 5.25 4.01 5.25H4ZM6.25 6C6.25 5.58579 6.58579 5.25 7 5.25H7.01C7.42421 5.25 7.76 5.58579 7.76 6V6.01C7.76 6.42421 7.42421 6.76 7.01 6.76H7C6.58579 6.76 6.25 6.42421 6.25 6.01V6ZM10 5.25C9.58579 5.25 9.25 5.58579 9.25 6V6.01C9.25 6.42421 9.58579 6.76 10 6.76H10.01C10.4242 6.76 10.76 6.42421 10.76 6.01V6C10.76 5.58579 10.4242 5.25 10.01 5.25H10Z"
        fill="#7041F1"
        fillRule="evenodd"
      />
    </svg>
  );
};
