export const esMX = {
  index: {
    header: {
      languageSelect: {
        alt: "Select language",
        "en-US": "Inglés",
        "pt-BR": "Portugués",
        "es-ES": "Español"
      }
    },
    main: {
      title: "La red social enfocada en <1>e-Sports</1>.",
      subtitle: "Tú centro de conexiones profesionales en mundo de <1>e-Sports</1>.",
      contact: {
        beforeBr: "Para cualquier duda, contáctanos",
        afterBr: "a través del correo"
      },
      discord: "Súmate a nuestro"
    },
    form: {
      callToAction: "Regístrate para participar de la revolución de juegos electrónicos.",
      title: "Sobre ti",
      name: {
        title: "Nombre",
        placeholder: "Nombre Apellido"
      },
      email: {
        title: "Correo",
        placeholder: "correo@ejemplo.com"
      },
      birthDate: {
        title: "Fecha de nacimiento",
        placeholder: "DD/MM/AAAA"
      },
      acceptTerms: "Acepto los <1>términos de uso</1>",
      acceptCommunication: "Acepto recibir comunicaciones por correo",
      submitButton: "Pre-regístrate"
    },
    popup: {
      nameError: "Por favor, proporciona tu nombre para continuar.",
      emailError: "Por favor, proporciona una dirección de correo válida para continuar.",
      birthDateError: "Por favor, proporciona una fecha de nacimiento válida para continuar.",
      termsError: "Por favor, acepta los términos de uso para continuar.",
      updateSuccess: "¡Tu información ha sido actualizada con éxito!",
      successLink: "¡Súmate a nuestro Discord!",
      successMessage: "Tu pre-registro se ha completado con éxito.",
      closeButton: "Cerrar"
    }
  },
  terms: {
    use: {
      title: "Terms of Use - Spectate.pro",
      list: [
        {
          title: "Acceptance of Terms",
          description: "By signing up for the <1>Spectate.pro</1> platform, you agree to all the terms of use outlined here.",
          link: "https://spectate.pro"
        },
        {
          title: "Data Collection",
          description: "We collect personal information such as your name, email, date of birth, and browsing data to provide a personalized and secure experience."
        },
        {
          title: "Data Usage",
          description: "We use your data to:",
          list: [
            "Improve our services",
            "Offer relevant content",
            "Send important communications",
            "Analyze and understand platform usage"
          ]
        },
        {
          title: "Data Storage",
          description: "Your data is securely stored on our servers with stringent measures to protect against unauthorized access, misuse, and disclosure."
        },
        {
          title: "Data Sharing",
          description: "We may share your data with trusted partners to enhance the services provided, always respecting your privacy and complying with applicable laws."
        },
        {
          title: "User Rights",
          description: "You have the right to access, correct, and delete your personal information at any time. You can also withdraw your consent for data usage as stipulated under relevant privacy laws."
        },
        {
          title: "Consent",
          description: "By signing up for <1>Spectate.pro</1>, you consent to the collection, use, storage, and sharing of your data as described in these terms.",
          link: "https://spectate.pro"
        },
        {
          title: "Changes to Terms",
          description: "We may update these terms periodically. We will notify you of significant changes through our platform or via email."
        },
        {
          title: "Contact",
          description: "For any questions or requests regarding your data, please contact us at <1>contact@spectate.pro.</1>",
          link: "mailto:contact@spectate.pro"
        },
      ]
    },
    privacy: {
      title: "Privacy Policy",
      list: [
        {
          title: "Introduction",
          description: "<1>Spectate.pro</1> respects your privacy and is committed to protecting your personal information.",
          link: "https://spectate.pro"
        },
        {
          title: "Data Collected",
          description: "We collect data you provide directly and information automatically generated by your use of the platform.",
        },
        {
          title: "Purpose of Data Processing",
          description: "Data is processed to provide and improve our services, communicate with you, and fulfill legal obligations.",
        },
        {
          title: "Data Security",
          description: "We adopt technical and administrative measures to protect your personal data.",
        },
        {
          title: "Data Sharing",
          description: "We only share data with third parties when necessary to operate and improve our services or as required by law.",
        },
        {
          title: "Your Rights",
          description: "You have the right to access, correct, delete, and request the portability of your data, and to withdraw consent at any time.",
        },
        {
          title: "Changes to Policy",
          description: "We may revise this policy periodically. We will inform you of significant changes.",
        },
        {
          title: "Contact",
          description: "For any privacy-related questions, contact us at <1>contact@spectate.pro</1>.",
          link: "mailto:contact@spectate.pro"
        },
      ]
    },
    agree: "By using <1>Spectate.pro</1>, you agree to these terms and policies."
  }
}