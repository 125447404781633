/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const HeroiconsOutlineForward = ({ className }) => {
  return (
    <svg
      className={`heroicons-outline-forward ${className}`}
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M3 8.68855C3 7.82475 3.93317 7.28321 4.68316 7.71178L11.7906 11.7732C12.5464 12.2051 12.5464 13.2949 11.7906 13.7267L4.68316 17.7882C3.93317 18.2167 3 17.6752 3 16.8114V8.68855Z"
        stroke="#7041F1"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        className="path"
        d="M12.75 8.68855C12.75 7.82475 13.6832 7.28321 14.4332 7.71178L21.5406 11.7732C22.2964 12.2051 22.2964 13.2949 21.5406 13.7267L14.4332 17.7882C13.6832 18.2167 12.75 17.6752 12.75 16.8114V8.68855Z"
        stroke="#7041F1"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </svg>
  );
};
