import React, { useState } from 'react';
import './App.css'; // Importe o arquivo CSS
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faCheck } from '@fortawesome/free-solid-svg-icons';

function AboutPage() {
  const [showSections, setShowSections] = useState({
    projeto: false,
    parceiros: false,
    socios: false,
  });
  const [typedTitles, setTypedTitles] = useState({
    projeto: 'Nós somos a *Spectate*, Startup que busca criar um canal de comunicação _limpo, prático e profissional_ entre Jogadores Profissionais, Times, Patrocinadores, Streamers e Eventos. Tambêm buscamos dar toda a infraestrutura de __métricas, dados, e serviços__ para que estes agentes possam _encontrar e disponibilizar oportunidades de forma rápida, clara, eficiente e direccionada._',
    parceiros: 'Atualmente somos acelerado pela *Baita*, sempre estamos buscando novas parcerias que não impactem nossos contratos atuais, e somos muito abertos a contatos! Qualquer dúvida ou comentário pode nos encontrar no email: _contact@spectate.pro_',
    socios: 'A *Spectate* é formada atualmente por dois sócios maioritários e ideadores da Startup. _*Murilo Fantozzi*_, Ex jogador do circuito semi profissional brasileiro de LoL e Engenheiro de Dados e _*Diego Sulzbeck Villalobos*_, Engenheiro de Software e Cientista de Dados.',
  });

  const toggleSection = (section) => {
    setShowSections(prev => ({ ...prev, [section]: !prev[section] }));
    if (!showSections[section]) {
      // Inicie a digitação do texto da seção
      typeTitle(section);
    }
  };

  const applyStyles = (text) => {
    // Substitui marcas por tags HTML para negrito e sublinhado
    return text.replace(/\*(.*?)\*/g, '<strong>$1</strong>')
               .replace(/_(.*?)_/g, `<span style="background-color: rgba(26, 18, 69, 0.2);">$1</span>`);
  };

  const typeTitle = (fieldName) => {
    let index = 0;
    const fullText = typedTitles[fieldName];

    const typeChar = () => {
      if (index < fullText.length+1) {
        const typed = fullText.substring(0, index);
        const styledText = applyStyles(typed);
        setTypedTitles(prevTitles => ({ ...prevTitles, [fieldName]: styledText }));
        
        // Definir a velocidade básica
        let delay = Math.random() * 30 + 10; // Randomiza

        // Verifica o último caractere digitado
        const lastChar = fullText[index - 1];
        if (lastChar === ',') {
          delay *= 4; // Duplica a pausa para vírgulas
        } else if (lastChar === '.') {
          delay *= 8; // Triplica a pausa para pontos
        }

        index++;
        setTimeout(typeChar, delay);
      }
    };

    typeChar();
  };



    return (
    <div className="LandingPage">
      <h2 style={{ color: '#3a2d5b', cursor: 'pointer', display: 'flex', alignItems: 'center' }}
          onClick={() => toggleSection('projeto')}>
        {showSections.projeto ? <FontAwesomeIcon icon={faCheck} /> : <FontAwesomeIcon icon={faChevronDown} />}
        <span style={{ marginLeft: '10px' }}>Projeto</span>
      </h2>
      <hr style={{ width: '100%', borderColor: '#3a2d5b', opacity: 0.5 }} />
      {showSections.projeto && (
        <div>
          <div className="typing-effect-text" dangerouslySetInnerHTML={{ __html: typedTitles.projeto }}>

          </div>
        </div>
      )}
      <hr style={{ width: '100%', borderColor: '#3a2d5b', opacity: 0.5 }} />

      <h2 style={{ color: '#3a2d5b', cursor: 'pointer', display: 'flex', alignItems: 'center' }}
          onClick={() => toggleSection('parceiros')}>
        {showSections.parceiros ? <FontAwesomeIcon icon={faCheck} /> : <FontAwesomeIcon icon={faChevronDown} />}
        <span style={{ marginLeft: '10px' }}>Parceiros</span>
      </h2>
      <hr style={{ width: '100%', borderColor: '#3a2d5b', opacity: 0.5 }} />
      {showSections.parceiros && (
        <div>
         <div className="typing-effect-text" dangerouslySetInnerHTML={{ __html: typedTitles.parceiros}}>

         </div>
        </div>
      )}
      <hr style={{ width: '100%', borderColor: '#3a2d5b', opacity: 0.5 }} />

      <h2 style={{ color: '#3a2d5b', cursor: 'pointer', display: 'flex', alignItems: 'center' }}
          onClick={() => toggleSection('socios')}>
        {showSections.socios ? <FontAwesomeIcon icon={faCheck} /> : <FontAwesomeIcon icon={faChevronDown} />}
        <span style={{ marginLeft: '10px' }}>Sócios</span>
      </h2>
      <hr style={{ width: '100%', borderColor: '#3a2d5b', opacity: 0.5 }} />
      {showSections.socios && (
        <div>
         <div className="typing-effect-text" dangerouslySetInnerHTML={{ __html: typedTitles.socios}}>

         </div>
        </div>
      )}
      <hr style={{ width: '100%', borderColor: '#3a2d5b', opacity: 0.5 }} />
    </div>
  );

}

export default AboutPage;
  