import React from 'react';

const Loading = ({ className, color }) => {
  return (
    <div className={className} style={{ color }}>
      Loading...
    </div>
  );
};

export default Loading;
