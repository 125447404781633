/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const HeroiconsSolidBuildingOffice = ({ className }) => {
  return (
    <svg
      className={`heroicons-solid-building-office ${className}`}
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        clipRule="evenodd"
        d="M4.5 2.25C4.08579 2.25 3.75 2.58579 3.75 3C3.75 3.41421 4.08579 3.75 4.5 3.75V20.25H3.75C3.33579 20.25 3 20.5858 3 21C3 21.4142 3.33579 21.75 3.75 21.75H20.25C20.6642 21.75 21 21.4142 21 21C21 20.5858 20.6642 20.25 20.25 20.25H19.5V3.75C19.9142 3.75 20.25 3.41421 20.25 3C20.25 2.58579 19.9142 2.25 19.5 2.25H4.5ZM9 6C8.58579 6 8.25 6.33579 8.25 6.75C8.25 7.16421 8.58579 7.5 9 7.5H10.5C10.9142 7.5 11.25 7.16421 11.25 6.75C11.25 6.33579 10.9142 6 10.5 6H9ZM8.25 9.75C8.25 9.33579 8.58579 9 9 9H10.5C10.9142 9 11.25 9.33579 11.25 9.75C11.25 10.1642 10.9142 10.5 10.5 10.5H9C8.58579 10.5 8.25 10.1642 8.25 9.75ZM9 12C8.58579 12 8.25 12.3358 8.25 12.75C8.25 13.1642 8.58579 13.5 9 13.5H10.5C10.9142 13.5 11.25 13.1642 11.25 12.75C11.25 12.3358 10.9142 12 10.5 12H9ZM12.75 6.75C12.75 6.33579 13.0858 6 13.5 6H15C15.4142 6 15.75 6.33579 15.75 6.75C15.75 7.16421 15.4142 7.5 15 7.5H13.5C13.0858 7.5 12.75 7.16421 12.75 6.75ZM13.5 9C13.0858 9 12.75 9.33579 12.75 9.75C12.75 10.1642 13.0858 10.5 13.5 10.5H15C15.4142 10.5 15.75 10.1642 15.75 9.75C15.75 9.33579 15.4142 9 15 9H13.5ZM12.75 12.75C12.75 12.3358 13.0858 12 13.5 12H15C15.4142 12 15.75 12.3358 15.75 12.75C15.75 13.1642 15.4142 13.5 15 13.5H13.5C13.0858 13.5 12.75 13.1642 12.75 12.75ZM9 19.5V17.25C9 16.8358 9.33579 16.5 9.75 16.5H14.25C14.6642 16.5 15 16.8358 15 17.25V19.5C15 19.9142 14.6642 20.25 14.25 20.25H9.75C9.33579 20.25 9 19.9142 9 19.5Z"
        fill="#7041F1"
        fillRule="evenodd"
      />
    </svg>
  );
};
