/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const HeroiconsSolidHomeModern = ({ className }) => {
  return (
    <svg
      className={`heroicons-solid-home-modern ${className}`}
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M19.0061 3.70481C19.3954 3.56325 19.5962 3.13293 19.4547 2.74365C19.3131 2.35438 18.8828 2.15356 18.4935 2.29511L6 6.83822V2.99996C6 2.58575 5.66421 2.24996 5.25 2.24996H3.75C3.33579 2.24996 3 2.58575 3 2.99996V7.92913L1.99353 8.29511C1.60426 8.43667 1.40344 8.86699 1.54499 9.25627C1.68655 9.64554 2.11687 9.84636 2.50615 9.7048L19.0061 3.70481Z"
        fill="#7041F1"
      />
      <path
        className="path"
        clipRule="evenodd"
        d="M3.01896 11.1145L18 5.66684V9.08833L22.0061 10.5451C22.3954 10.6867 22.5962 11.117 22.4547 11.5063C22.3131 11.8955 21.8828 12.0964 21.4935 11.9548L20.9998 11.7753V20.25H21.75C22.1642 20.25 22.5 20.5857 22.5 21C22.5 21.4142 22.1642 21.75 21.75 21.75H2.25C1.83579 21.75 1.5 21.4142 1.5 21C1.5 20.5857 1.83579 20.25 2.25 20.25H3V11.1213L3.01896 11.1145ZM18 20.25V10.6844L19.4998 11.2298V20.25H18ZM9 14.25C8.58579 14.25 8.25 14.5857 8.25 15V19.5C8.25 19.9142 8.58579 20.25 9 20.25H12C12.4142 20.25 12.75 19.9142 12.75 19.5V15C12.75 14.5857 12.4142 14.25 12 14.25H9Z"
        fill="#7041F1"
        fillRule="evenodd"
      />
    </svg>
  );
};
