/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const HeroiconsSolidMoon = ({ className }) => {
  return (
    <svg
      className={`heroicons-solid-moon ${className}`}
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        clipRule="evenodd"
        d="M9.52839 1.71784C9.74339 1.93284 9.80731 2.25638 9.69021 2.53699C9.2458 3.60202 9 4.77141 9 6.00011C9 10.9707 13.0294 15.0001 18 15.0001C19.2287 15.0001 20.3981 14.7543 21.4631 14.3099C21.7437 14.1928 22.0673 14.2567 22.2823 14.4717C22.4973 14.6867 22.5612 15.0103 22.4441 15.2909C20.8618 19.0828 17.1183 21.7501 12.75 21.7501C6.95101 21.7501 2.25 17.0491 2.25 11.2501C2.25 6.88182 4.91735 3.13827 8.70924 1.55601C8.98985 1.43892 9.31338 1.50284 9.52839 1.71784Z"
        fill="#7041F1"
        fillRule="evenodd"
      />
    </svg>
  );
};
