/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const HeroiconsOutlinePlay = ({ className }) => {
  return (
    <svg
      className={`heroicons-outline-play ${className}`}
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M5.25 5.65266C5.25 4.79699 6.1674 4.25455 6.91716 4.66692L18.4577 11.0142C19.2349 11.4417 19.2349 12.5583 18.4577 12.9857L6.91716 19.333C6.1674 19.7454 5.25 19.203 5.25 18.3473V5.65266Z"
        stroke="#7041F1"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </svg>
  );
};
