/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const IndivIConsSolidFacebook = ({ className }) => {
  return (
     <svg
      className={`heroicons-solid-x-mark ${className}`}
      fill="none" 
      height="24"
      viewBox="0 0 8 17"
      width="24"
      xmlns="http://www.w3.org/2000/svg">
      <path className="path" clipRule="evenodd" fillRule="evenodd" fill= "none" d="M5.36213 3.83063V6.22737H7.99932L7.58173 9.45592H5.36213V16.8944C4.91712 16.9638 4.46182 17 3.99966 17C3.46619 17 2.94232 16.9522 2.43216 16.8597V9.45592H0V6.22737H2.43216V3.29485C2.43216 1.47552 3.74389 0 5.36282 0V0.00154332C5.36762 0.00154332 5.37173 0 5.37653 0H8V2.79222H6.28576C5.77629 2.79222 5.36282 3.25708 5.36282 3.82986L5.36213 3.83063Z" />
    </svg>
  );
};
