/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const HeroiconsSolidArrowTrendingUp = ({ className }) => {
  return (
    <svg
      className={`heroicons-solid-arrow-trending-up ${className}`}
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        clipRule="evenodd"
        d="M15.2194 6.26791C15.3679 5.88121 15.8017 5.68806 16.1884 5.8365L22.1297 8.11714C22.3154 8.18842 22.4651 8.33056 22.546 8.51227C22.627 8.69399 22.6324 8.9004 22.5611 9.0861L20.2804 15.0274C20.132 15.4141 19.6982 15.6072 19.3115 15.4588C18.9248 15.3103 18.7316 14.8765 18.8801 14.4898L20.5118 10.2389L19.4253 10.7227C16.9721 11.8149 15.1036 13.6757 13.975 15.8962C13.8662 16.1104 13.6614 16.2594 13.4241 16.2971C13.1869 16.3348 12.946 16.2566 12.7761 16.0867L9 12.3107L2.78033 18.5303C2.48744 18.8232 2.01256 18.8232 1.71967 18.5303C1.42678 18.2374 1.42678 17.7626 1.71967 17.4697L8.46967 10.7197C8.61032 10.579 8.80109 10.5 9 10.5C9.19891 10.5 9.38968 10.579 9.53033 10.7197L13.1363 14.3257C14.4369 12.2045 16.3711 10.4406 18.8152 9.35238L19.9017 8.86863L15.6508 7.23687C15.2641 7.08843 15.071 6.65461 15.2194 6.26791Z"
        fill="#7041F1"
        fillRule="evenodd"
      />
    </svg>
  );
};
