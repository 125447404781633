import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import AboutPage from './AboutPage';
import LandingPage from './landingPage';
import { FlashLogo } from './components/FlashLogo';
import './App.css';
import { Logo } from './components/Logo';
import { TermsPage } from './pages/TermsPage';

function App() {
  const [showFlashLogo, setShowFlashLogo] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => setShowFlashLogo(false), 5000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Router className="App">
      <div>
        <Routes>
          {/* <Route path="/about" element={<AboutPage />} /> */}
          <Route path="/" element={<LandingPage />} />
          <Route path="/terms" element={<TermsPage />} />
          {/* <Route path="/calendario" element={<Calendario />} /> */}
        </Routes>
      </div>
    </Router>
  );
}

export default App;
