/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const HeroiconsSolidBeaker = ({ className }) => {
  return (
    <svg
      className={`heroicons-solid-beaker ${className}`}
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        clipRule="evenodd"
        d="M10.5 3.79758V8.81802C10.5 9.61367 10.1839 10.3767 9.62134 10.9393L7.2443 13.3164C8.99164 13.192 10.7578 13.5404 12.3354 14.3292C14.0988 15.2109 16.1395 15.442 18.048 14.9649L18.333 14.8937L14.3787 10.9393C13.8161 10.3767 13.5 9.61367 13.5 8.81802V3.79758C13.0042 3.76602 12.504 3.75 12 3.75C11.496 3.75 10.9959 3.76602 10.5 3.79758ZM15 3.93576C15.3732 3.93623 15.6969 3.65833 15.7443 3.27849C15.7955 2.86746 15.5039 2.4927 15.0928 2.44144C14.8362 2.40945 14.5784 2.38138 14.3195 2.3573C13.5557 2.28628 12.782 2.25 12 2.25C11.2181 2.25 10.4444 2.28628 9.68058 2.3573C9.42163 2.38138 9.16382 2.40945 8.90721 2.44144C8.49618 2.4927 8.20453 2.86746 8.25578 3.27849C8.30315 3.65833 8.62679 3.93623 9.00002 3.93576V8.81802C9.00002 9.21584 8.84198 9.59737 8.56068 9.87868L2.26748 16.1719C0.646344 17.793 1.36449 20.6474 3.73839 21.0527C6.42422 21.5112 9.18453 21.75 12 21.75C14.8155 21.75 17.5758 21.5112 20.2616 21.0527C22.6356 20.6474 23.3537 17.793 21.7326 16.1719L15.4394 9.87868C15.1581 9.59737 15 9.21584 15 8.81802V3.93576Z"
        fill="#7041F1"
        fillRule="evenodd"
      />
    </svg>
  );
};
