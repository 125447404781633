/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const HeroiconsSolidCurrencyDollar = ({ className }) => {
  return (
    <svg
      className={`heroicons-solid-currency-dollar ${className}`}
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M10.4636 8.74626C10.6908 8.56577 10.9607 8.43451 11.25 8.35249V11.1474C10.9552 11.0637 10.686 10.9304 10.4636 10.7537C10.0699 10.441 9.91752 10.073 9.91752 9.75C9.91752 9.42705 10.0699 9.05904 10.4636 8.74626Z"
        fill="#7041F1"
      />
      <path
        className="path"
        d="M12.75 15.6616V12.8383C13.0972 12.9228 13.4138 13.0658 13.6713 13.259C14.0978 13.5788 14.25 13.9448 14.25 14.25C14.25 14.5551 14.0978 14.9211 13.6713 15.241C13.4138 15.4342 13.0972 15.5772 12.75 15.6616Z"
        fill="#7041F1"
      />
      <path
        className="path"
        clipRule="evenodd"
        d="M12 2.25C6.61522 2.25 2.25 6.61522 2.25 12C2.25 17.3848 6.61522 21.75 12 21.75C17.3848 21.75 21.75 17.3848 21.75 12C21.75 6.61522 17.3848 2.25 12 2.25ZM12.75 6C12.75 5.58579 12.4142 5.25 12 5.25C11.5858 5.25 11.25 5.58579 11.25 6V6.81563C10.6231 6.92669 10.0253 7.17873 9.53058 7.57176C8.81822 8.13765 8.41752 8.9213 8.41752 9.75C8.41752 10.5787 8.81822 11.3623 9.53058 11.9282C10.033 12.3274 10.6327 12.575 11.25 12.6843V15.6616C10.9028 15.5771 10.5864 15.4341 10.3289 15.241L9.45001 14.5818C9.11865 14.3333 8.64854 14.4004 8.40001 14.7318C8.15147 15.0631 8.21862 15.5332 8.54999 15.7818L9.42886 16.441C9.96206 16.8409 10.5979 17.0856 11.25 17.1903V18C11.25 18.4142 11.5858 18.75 12 18.75C12.4142 18.75 12.75 18.4142 12.75 18V17.1904C13.4021 17.0856 14.0381 16.8409 14.5714 16.441C15.3164 15.8821 15.75 15.0965 15.75 14.25C15.75 13.4034 15.3164 12.6178 14.5714 12.059C14.0381 11.659 13.4021 11.4143 12.75 11.3096V8.35257C13.0392 8.4346 13.309 8.56583 13.5361 8.74626L13.951 9.07585C14.2753 9.3335 14.7471 9.27944 15.0048 8.95511C15.2624 8.63078 15.2084 8.15899 14.884 7.90135L14.4691 7.57176C13.9745 7.17879 13.3768 6.92677 12.75 6.81567V6Z"
        fill="#7041F1"
        fillRule="evenodd"
      />
    </svg>
  );
};
