/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const HeroiconsMiniSpeakerWave = ({ className }) => {
  return (
    <svg
      className={`heroicons-mini-speaker-wave ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M10 3.74999C10 3.45077 9.82215 3.18018 9.54747 3.0615C9.2728 2.94282 8.95387 2.99876 8.73598 3.20384L4.70257 6.99999H3.16724C2.85725 6.99999 2.5792 7.1907 2.46756 7.4799C2.16534 8.26284 2 9.11299 2 9.99999C2 10.887 2.16534 11.7371 2.46756 12.5201C2.5792 12.8093 2.85725 13 3.16724 13H4.70257L8.73598 16.7961C8.95387 17.0012 9.2728 17.0571 9.54747 16.9385C9.82215 16.8198 10 16.5492 10 16.25V3.74999Z"
        fill="#7041F1"
      />
      <path
        className="path"
        d="M15.9498 5.05026C15.6569 4.75736 15.182 4.75736 14.8891 5.05026C14.5962 5.34315 14.5962 5.81802 14.8891 6.11092C17.037 8.2588 17.037 11.7412 14.8891 13.8891C14.5962 14.182 14.5962 14.6569 14.8891 14.9498C15.182 15.2426 15.6569 15.2426 15.9498 14.9498C18.6834 12.2161 18.6834 7.78393 15.9498 5.05026Z"
        fill="#7041F1"
      />
      <path
        className="path"
        d="M13.8287 7.17157C13.5358 6.87867 13.0609 6.87867 12.768 7.17157C12.4751 7.46446 12.4751 7.93933 12.768 8.23223C13.7443 9.20854 13.7443 10.7914 12.768 11.7678C12.4751 12.0607 12.4751 12.5355 12.768 12.8284C13.0609 13.1213 13.5358 13.1213 13.8287 12.8284C15.3908 11.2663 15.3908 8.73366 13.8287 7.17157Z"
        fill="#7041F1"
      />
    </svg>
  );
};
