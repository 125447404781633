/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const HeroiconsOutlineShieldExclamation = ({ className }) => {
  return (
    <svg
      className={`heroicons-outline-shield-exclamation ${className}`}
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M12 8.99997V12.75M12 2.71423C9.8495 4.75086 6.94563 5.99998 3.75 5.99998C3.69922 5.99998 3.64852 5.99967 3.59789 5.99904C3.2099 7.17915 3 8.44008 3 9.75003C3 15.3415 6.82432 20.0398 12 21.372C17.1757 20.0398 21 15.3415 21 9.75003C21 8.44008 20.7901 7.17915 20.4021 5.99904C20.3515 5.99967 20.3008 5.99998 20.25 5.99998C17.0544 5.99998 14.1505 4.75086 12 2.71423ZM12 15.75H12.0075V15.7575H12V15.75Z"
        stroke="#7041F1"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </svg>
  );
};
