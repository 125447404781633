/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const HeroiconsSolidArrowPathRoundedSquare = ({ className }) => {
  return (
    <svg
      className={`heroicons-solid-arrow-path-rounded-square ${className}`}
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        clipRule="evenodd"
        d="M12 5.25C13.2131 5.25 14.4153 5.2957 15.6052 5.38545C17.2196 5.50723 18.4928 6.78038 18.6146 8.39481C18.6586 8.97827 18.692 9.56471 18.7146 10.1539L17.0303 8.46967C16.7374 8.17678 16.2626 8.17678 15.9697 8.46967C15.6768 8.76256 15.6768 9.23744 15.9697 9.53033L18.9697 12.5303C19.2626 12.8232 19.7374 12.8232 20.0303 12.5303L23.0303 9.53033C23.3232 9.23744 23.3232 8.76256 23.0303 8.46967C22.7374 8.17678 22.2626 8.17678 21.9697 8.46967L20.2181 10.2212C20.1948 9.57151 20.1588 8.92503 20.1103 8.28199C19.9327 5.92812 18.0719 4.06725 15.718 3.8897C14.4907 3.79712 13.2508 3.75 12 3.75C10.7492 3.75 9.50933 3.79712 8.28199 3.8897C5.92812 4.06725 4.06725 5.92812 3.8897 8.28199C3.87284 8.50552 3.85748 8.72948 3.84365 8.95384C3.81815 9.36727 4.13264 9.72308 4.54606 9.74858C4.95949 9.77407 5.31531 9.45959 5.3408 9.04616C5.35422 8.82864 5.3691 8.61152 5.38545 8.39481C5.50723 6.78038 6.78038 5.50723 8.39481 5.38545C9.58465 5.2957 10.7869 5.25 12 5.25ZM5.03033 11.4697C4.73744 11.1768 4.26256 11.1768 3.96967 11.4697L0.96967 14.4697C0.676777 14.7626 0.676777 15.2374 0.96967 15.5303C1.26256 15.8232 1.73744 15.8232 2.03033 15.5303L3.78186 13.7788C3.80517 14.4285 3.84119 15.075 3.8897 15.718C4.06725 18.0719 5.92812 19.9327 8.28199 20.1103C9.50933 20.2029 10.7492 20.25 12 20.25C13.2508 20.25 14.4907 20.2029 15.718 20.1103C18.0719 19.9327 19.9327 18.0719 20.1103 15.718C20.1272 15.4945 20.1425 15.2705 20.1564 15.0462C20.1818 14.6327 19.8674 14.2769 19.4539 14.2514C19.0405 14.2259 18.6847 14.5404 18.6592 14.9538C18.6458 15.1714 18.6309 15.3885 18.6146 15.6052C18.4928 17.2196 17.2196 18.4928 15.6052 18.6146C14.4153 18.7043 13.2131 18.75 12 18.75C10.7869 18.75 9.58465 18.7043 8.39481 18.6146C6.78038 18.4928 5.50723 17.2196 5.38545 15.6052C5.34144 15.0217 5.30802 14.4353 5.28539 13.8461L6.96967 15.5303C7.26256 15.8232 7.73744 15.8232 8.03033 15.5303C8.32322 15.2374 8.32322 14.7626 8.03033 14.4697L5.03033 11.4697Z"
        fill="#7041F1"
        fillRule="evenodd"
      />
    </svg>
  );
};
