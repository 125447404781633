/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const HeroiconsOutlineScissors = ({ className }) => {
  return (
    <svg
      className={`heroicons-outline-scissors ${className}`}
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M7.84792 8.25007L9.38449 9.13721M7.84792 8.25007C7.01949 9.68494 5.18507 10.1765 3.7502 9.34809C2.31532 8.51966 1.82369 6.68489 2.65212 5.25001C3.48055 3.81513 5.31532 3.32351 6.7502 4.15194C8.18507 4.98036 8.67635 6.81519 7.84792 8.25007ZM9.38449 9.13721C10.043 9.51742 10.4538 10.2153 10.4666 10.9756C10.4725 11.3272 10.5208 11.6706 10.607 12.0001M9.38449 9.13721L11.4609 10.336M7.84792 15.7501L9.38449 14.863M7.84792 15.7501C8.67635 17.185 8.18507 19.0197 6.7502 19.8481C5.31532 20.6765 3.48055 20.1849 2.65212 18.75C1.82369 17.3151 2.31532 15.4804 3.7502 14.6519C5.18507 13.8235 7.01949 14.3153 7.84792 15.7501ZM9.38449 14.863C10.043 14.4828 10.4538 13.7849 10.4666 13.0246C10.4725 12.673 10.5208 12.3296 10.607 12.0001M9.38449 14.863L11.4609 13.6642M11.4609 10.336C11.9882 9.699 12.6991 9.21096 13.5294 8.95702L18.8541 7.32855C19.6607 7.08189 20.5203 7.06684 21.3349 7.28513L22.1373 7.50014L14.3432 12.0001M11.4609 10.336C11.062 10.8178 10.7682 11.3848 10.607 12.0001M14.3432 12.0001L22.1373 16.5001L21.3349 16.7151C20.5203 16.9334 19.6607 16.9183 18.8541 16.6717L13.5294 15.0432C12.6991 14.7892 11.9882 14.3012 11.4609 13.6642M14.3432 12.0001L11.4609 13.6642"
        stroke="#7041F1"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </svg>
  );
};
