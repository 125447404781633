import PropTypes from "prop-types";
import React, {forwardRef} from "react";
import Loading from "../Loading";
import "./style.css";

export const Button = forwardRef(({ text = "Button", icon = false, type, state, size, onClick }, ref) => {
  return (
    <button className={`button state-${state} ${type} ${size}`} onClick={onClick}>
      <div className="content">
        {["default", "disabled", "focused", "hover", "pressed"].includes(state) && (
          <div className="text-wrapper">{text}</div>
        )}

        {state === "loading" && (
          <>
            <Loading
              className="icon-outlined"
              color={type === "primary" ? "#F3F4F7" : type === "link" ? "#9471F4" : "#303546"}
            />
            <div className="div">{text}</div>
          </>
        )}
      </div>
    </button>
  );
});

Button.propTypes = {
  text: PropTypes.string,
  icon: PropTypes.bool,
  type: PropTypes.oneOf(["dashed", "default", "primary", "link", "text"]),
  state: PropTypes.oneOf(["default", "pressed", "focused", "loading", "hover", "disabled"]),
  size: PropTypes.oneOf(["large", "small", "default"]),
};
