/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const HeroiconsSolidCheck = ({ className }) => {
  return (
    <svg
      className={`heroicons-solid-check ${className}`}
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        clipRule="evenodd"
        d="M19.916 4.62598C20.2607 4.85575 20.3538 5.3214 20.124 5.66604L11.124 19.166C10.9994 19.353 10.7975 19.4742 10.5739 19.4964C10.3503 19.5185 10.1286 19.4392 9.96967 19.2803L3.96967 13.2803C3.67678 12.9875 3.67678 12.5126 3.96967 12.2197C4.26256 11.9268 4.73744 11.9268 5.03033 12.2197L10.3834 17.5728L18.876 4.83399C19.1057 4.48935 19.5714 4.39622 19.916 4.62598Z"
        fill="#7041F1"
        fillRule="evenodd"
      />
    </svg>
  );
};
