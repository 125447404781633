// src/components/Popup/Popup.jsx
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '../Button'; // Adjust if Button is not directly under components

export const Popup = ({ onClose, text, link, link_name, buttonText }) => {
  const navigate = useNavigate();

  const handleConfirm = () => {
    navigate("/");
    onClose();
  };

  return (
    <div className="popup-overlay">
      <div className="popup-content">
        {text && <p>{text}</p>}
        {link && <a href={link} target="_blank" rel="noopener noreferrer">{link_name}</a>}
        <div className="field-margin center-button">
          <Button size="large" state="default" text={buttonText} type="primary" onClick={handleConfirm}/>
        </div>
      </div>
    </div>
  );
};
