/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const HeroiconsSolidShieldExclamation = ({ className }) => {
  return (
    <svg
      className={`heroicons-solid-shield-exclamation ${className}`}
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        clipRule="evenodd"
        d="M11.4843 2.16968C11.7735 1.89575 12.2265 1.89575 12.5157 2.16968C14.5325 4.07965 17.2538 5.24998 20.25 5.24998C20.2977 5.24998 20.3453 5.24969 20.3928 5.2491C20.7202 5.24503 21.0123 5.45378 21.1146 5.76479C21.5271 7.01955 21.75 8.35951 21.75 9.75003C21.75 15.692 17.6859 20.683 12.1869 22.0983C12.0643 22.1299 11.9357 22.1299 11.8131 22.0983C6.31406 20.683 2.25 15.692 2.25 9.75003C2.25 8.35951 2.47287 7.01955 2.88541 5.76479C2.98767 5.45378 3.27984 5.24503 3.60721 5.2491C3.65473 5.24969 3.70233 5.24998 3.75 5.24998C6.74624 5.24998 9.46752 4.07965 11.4843 2.16968ZM12 8.24997C12.4142 8.24997 12.75 8.58576 12.75 8.99997V12.75C12.75 13.1642 12.4142 13.5 12 13.5C11.5858 13.5 11.25 13.1642 11.25 12.75V8.99997C11.25 8.58576 11.5858 8.24997 12 8.24997ZM12 15C11.5858 15 11.25 15.3358 11.25 15.75V15.7575C11.25 16.1717 11.5858 16.5075 12 16.5075H12.0075C12.4217 16.5075 12.7575 16.1717 12.7575 15.7575V15.75C12.7575 15.3358 12.4217 15 12.0075 15H12Z"
        fill="#7041F1"
        fillRule="evenodd"
      />
    </svg>
  );
};
