/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const HeroiconsOutlineBeaker = ({ className }) => {
  return (
    <svg
      className={`heroicons-outline-beaker ${className}`}
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M9.74997 3.10408V8.81802C9.74997 9.41476 9.51292 9.98705 9.09097 10.409L4.99998 14.5M9.74997 3.10408C9.49883 3.12743 9.24881 3.15465 8.99997 3.18568M9.74997 3.10408C10.4907 3.03521 11.2412 3 12 3C12.7587 3 13.5092 3.03521 14.25 3.10408M14.25 3.10408V8.81802C14.25 9.41476 14.487 9.98705 14.909 10.409L19.8 15.3M14.25 3.10408C14.5011 3.12743 14.7511 3.15465 15 3.18568M19.8 15.3L18.2299 15.6925C16.1456 16.2136 13.9216 15.9608 12 15C10.0784 14.0392 7.85432 13.7864 5.77007 14.3075L4.99998 14.5M19.8 15.3L21.2022 16.7022C22.4341 17.9341 21.8527 20.0202 20.1354 20.3134C17.4911 20.7649 14.773 21 12 21C9.22696 21 6.50888 20.7649 3.86456 20.3134C2.14725 20.0202 1.56588 17.9341 2.79777 16.7022L4.99998 14.5"
        stroke="#7041F1"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </svg>
  );
};
