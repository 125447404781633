/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const HeroiconsMiniArrowsPointingIn = ({ className }) => {
  return (
    <svg
      className={`heroicons-mini-arrows-pointing-in ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M3.28033 2.21967C2.98744 1.92678 2.51256 1.92678 2.21967 2.21967C1.92678 2.51256 1.92678 2.98744 2.21967 3.28033L5.43934 6.5H2.75C2.33579 6.5 2 6.83579 2 7.25C2 7.66421 2.33579 8 2.75 8H7.25C7.66421 8 8 7.66421 8 7.25V2.75C8 2.33579 7.66421 2 7.25 2C6.83579 2 6.5 2.33579 6.5 2.75V5.43934L3.28033 2.21967Z"
        fill="#7041F1"
      />
      <path
        className="path"
        d="M13.5 2.75C13.5 2.33579 13.1642 2 12.75 2C12.3358 2 12 2.33579 12 2.75V7.25C12 7.66421 12.3358 8 12.75 8H17.25C17.6642 8 18 7.66421 18 7.25C18 6.83579 17.6642 6.5 17.25 6.5H14.5607L17.7803 3.28033C18.0732 2.98744 18.0732 2.51256 17.7803 2.21967C17.4874 1.92678 17.0126 1.92678 16.7197 2.21967L13.5 5.43934V2.75Z"
        fill="#7041F1"
      />
      <path
        className="path"
        d="M3.28033 17.7803L6.5 14.5607V17.25C6.5 17.6642 6.83579 18 7.25 18C7.66421 18 8 17.6642 8 17.25V12.75C8 12.3358 7.66421 12 7.25 12H2.75C2.33579 12 2 12.3358 2 12.75C2 13.1642 2.33579 13.5 2.75 13.5H5.43934L2.21967 16.7197C1.92678 17.0126 1.92678 17.4874 2.21967 17.7803C2.51256 18.0732 2.98744 18.0732 3.28033 17.7803Z"
        fill="#7041F1"
      />
      <path
        className="path"
        d="M13.5 14.5607L16.7197 17.7803C17.0126 18.0732 17.4874 18.0732 17.7803 17.7803C18.0732 17.4874 18.0732 17.0126 17.7803 16.7197L14.5607 13.5H17.25C17.6642 13.5 18 13.1642 18 12.75C18 12.3358 17.6642 12 17.25 12H12.75C12.3358 12 12 12.3358 12 12.75V17.25C12 17.6642 12.3358 18 12.75 18C13.1642 18 13.5 17.6642 13.5 17.25V14.5607Z"
        fill="#7041F1"
      />
    </svg>
  );
};
