/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const HeroiconsSolidLanguage = ({ className }) => {
  return (
    <svg
      className={`heroicons-solid-language ${className}`}
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        clipRule="evenodd"
        d="M9 2.25C9.41422 2.25 9.75 2.58579 9.75 3V4.50565C10.6344 4.519 11.5131 4.55589 12.3856 4.61576C13.295 4.67816 14.1977 4.7655 15.0928 4.87713C15.5038 4.92838 15.7955 5.30314 15.7442 5.71417C15.693 6.1252 15.3182 6.41685 14.9072 6.3656C14.2477 6.28336 13.5839 6.21471 12.9162 6.15993C12.3254 8.46971 11.306 10.6074 9.94794 12.4834C10.2648 12.8666 10.5979 13.236 10.9462 13.5905C11.2365 13.8859 11.2324 14.3607 10.937 14.6511C10.6416 14.9414 10.1667 14.9373 9.87636 14.6419C9.57349 14.3337 9.28117 14.015 9 13.6866C7.42887 15.522 5.50941 17.0501 3.34423 18.1686C2.97622 18.3587 2.52378 18.2145 2.33367 17.8465C2.14355 17.4785 2.28777 17.026 2.65578 16.8359C4.73757 15.7605 6.57213 14.2737 8.05208 12.4834C7.18599 11.2869 6.45757 9.984 5.89021 8.59774C5.73332 8.21439 5.9169 7.77643 6.30025 7.61954C6.6836 7.46265 7.12155 7.64622 7.27845 8.02957C7.74076 9.15919 8.31957 10.229 9 11.2242C10.0649 9.66674 10.881 7.92604 11.3912 6.05944C10.5992 6.01997 9.80196 6 9 6C6.99914 6 5.02766 6.12433 3.09281 6.3656C2.68178 6.41685 2.30702 6.1252 2.25577 5.71417C2.20451 5.30314 2.49617 4.92838 2.9072 4.87713C4.66114 4.65841 6.4438 4.5329 8.25 4.50565V3C8.25 2.58579 8.58579 2.25 9 2.25ZM15.75 9C16.0414 9 16.3064 9.16878 16.4296 9.43284L21.6796 20.6828C21.8548 21.0582 21.6925 21.5045 21.3172 21.6796C20.9418 21.8548 20.4955 21.6925 20.3204 21.3172L19.1224 18.75H12.3777L11.1796 21.3172C11.0045 21.6925 10.5582 21.8548 10.1828 21.6796C9.80749 21.5045 9.6452 21.0582 9.82037 20.6828L15.0704 9.43284C15.1936 9.16878 15.4586 9 15.75 9ZM13.0777 17.25H18.4224L15.75 11.5235L13.0777 17.25Z"
        fill="#7041F1"
        fillRule="evenodd"
      />
    </svg>
  );
};
