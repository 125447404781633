/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const HeroiconsMiniCake = ({ className }) => {
  return (
    <svg
      className={`heroicons-mini-cake ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M6.74999 0.97934L5.86612 1.86323C5.37796 2.35139 5.37796 3.14285 5.86612 3.631C6.35427 4.11916 7.14573 4.11916 7.63388 3.631C8.12204 3.14285 8.12204 2.35139 7.63388 1.86323L6.74999 0.97934Z"
        fill="#7041F1"
      />
      <path
        className="path"
        d="M13.25 0.97934L12.3661 1.86323C11.878 2.35139 11.878 3.14285 12.3661 3.631C12.8543 4.11916 13.6457 4.11916 14.1339 3.631C14.622 3.14285 14.622 2.35139 14.1339 1.86323L13.25 0.97934Z"
        fill="#7041F1"
      />
      <path
        className="path"
        d="M9.99999 0.97934L10.8839 1.86323C11.372 2.35139 11.372 3.14285 10.8839 3.631C10.3957 4.11916 9.60427 4.11916 9.11612 3.631C8.62796 3.14285 8.62796 2.35139 9.11612 1.86323L9.99999 0.97934Z"
        fill="#7041F1"
      />
      <path
        className="path"
        d="M7.5 5.75C7.5 5.33579 7.16421 5 6.75 5C6.33579 5 6 5.33579 6 5.75V6.21379C4.82122 6.51847 4 7.60432 4 8.8357V8.93022C4.10036 8.9096 4.20229 8.89229 4.30573 8.87844C6.16935 8.62876 8.07023 8.5 10 8.5C11.9298 8.5 13.8306 8.62876 15.6943 8.87844C15.7977 8.89229 15.8996 8.9096 16 8.93022V8.8357C16 7.60432 15.1788 6.51846 14 6.21379V5.75C14 5.33579 13.6642 5 13.25 5C12.8358 5 12.5 5.33579 12.5 5.75V6.06829C11.9195 6.0365 11.3361 6.01571 10.75 6.00613V5.75C10.75 5.33579 10.4142 5 10 5C9.58579 5 9.25 5.33579 9.25 5.75V6.00613C8.66391 6.01571 8.08051 6.0365 7.5 6.06829V5.75Z"
        fill="#7041F1"
      />
      <path
        className="path"
        d="M4.50491 10.3652C6.30269 10.1243 8.13702 10 10 10C11.863 10 13.6973 10.1243 15.4951 10.3652C16.9666 10.5623 18 11.8378 18 13.2789V13.9721C17.4297 13.9721 16.8594 13.8412 16.3354 13.5792C14.8652 12.8441 13.1348 12.8441 11.6646 13.5792C10.6167 14.1031 9.38329 14.1031 8.33541 13.5792C6.86524 12.8441 5.13476 12.8441 3.66459 13.5792C3.14065 13.8412 2.57032 13.9721 2 13.9721V13.2789C2 11.8379 3.03337 10.5623 4.50491 10.3652Z"
        fill="#7041F1"
      />
      <path
        className="path"
        d="M15.6646 14.9208C16.3997 15.2884 17.1998 15.4721 18 15.4721V16.5C18 17.3284 17.3284 18 16.5 18H3.5C2.67157 18 2 17.3284 2 16.5V15.4721C2.80016 15.4721 3.60032 15.2884 4.33541 14.9208C5.38329 14.3969 6.61671 14.3969 7.66459 14.9208C9.13476 15.6559 10.8652 15.6559 12.3354 14.9208C13.3833 14.3969 14.6167 14.3969 15.6646 14.9208Z"
        fill="#7041F1"
      />
    </svg>
  );
};
