/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const HeroiconsMiniSlashSeparator = ({ className }) => {
  return (
    <svg
      className={`heroicons-mini-slash-separator ${className}`}
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        clipRule="evenodd"
        d="M17.3354 1.32918C17.7059 1.51442 17.856 1.96492 17.6708 2.33541L7.6708 22.3354C7.48556 22.7059 7.03506 22.8561 6.66457 22.6708C6.29409 22.4856 6.14392 22.0351 6.32916 21.6646L16.3292 1.66459C16.5144 1.2941 16.9649 1.14393 17.3354 1.32918Z"
        fill="#7041F1"
        fillRule="evenodd"
      />
    </svg>
  );
};
