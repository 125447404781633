/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const HeroiconsSolidArrowPath = ({ className }) => {
  return (
    <svg
      className={`heroicons-solid-arrow-path ${className}`}
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        clipRule="evenodd"
        d="M4.75517 10.0589C5.82723 6.05793 9.93976 3.68356 13.9408 4.75562C15.2617 5.10958 16.4035 5.79377 17.3034 6.69531L19.2065 8.59838H16.0228C15.6085 8.59838 15.2728 8.93417 15.2728 9.34838C15.2728 9.76259 15.6085 10.0984 16.0228 10.0984H21.0154C21.2143 10.0984 21.4051 10.0193 21.5457 9.87867C21.6864 9.738 21.7654 9.5472 21.7654 9.34827V4.35578C21.7654 3.94156 21.4296 3.60578 21.0154 3.60578C20.6011 3.60578 20.2654 3.94156 20.2654 4.35578V7.53594L18.3651 5.63564C17.2839 4.55256 15.9114 3.73074 14.329 3.30673C9.52779 2.02026 4.59276 4.8695 3.30628 9.6707C3.19908 10.0708 3.43651 10.482 3.83661 10.5893C4.23671 10.6965 4.64796 10.459 4.75517 10.0589ZM20.1626 13.4109C19.7625 13.3037 19.3513 13.5411 19.2441 13.9412C18.172 17.9422 14.0595 20.3166 10.0585 19.2445C8.73749 18.8906 7.5957 18.2064 6.69585 17.3048L4.79396 15.4017H7.97677C8.39099 15.4017 8.72677 15.0659 8.72677 14.6517C8.72677 14.2375 8.39099 13.9017 7.97677 13.9017L2.98413 13.9017C2.56992 13.9017 2.23413 14.2375 2.23413 14.6517V19.6444C2.23413 20.0586 2.56992 20.3944 2.98413 20.3944C3.39834 20.3944 3.73413 20.0586 3.73413 19.6444V16.4632L5.6342 18.3645C6.71539 19.4477 8.08772 20.2694 9.67024 20.6934C14.4714 21.9799 19.4065 19.1306 20.6929 14.3294C20.8001 13.9293 20.5627 13.5181 20.1626 13.4109Z"
        fill="#7041F1"
        fillRule="evenodd"
      />
    </svg>
  );
};
