/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const IndivIConsSolidTwitch = ({ className }) => {
  return (
     <svg
      className={`heroicons-solid-x-mark ${className}`}
      fill="none" 
      height="24"
      viewBox="0 0 13 15"
      width="24"
      xmlns="http://www.w3.org/2000/svg">
      <path className="path" clipRule="evenodd" fillRule="evenodd" fill= "none" d="M2.678 0L0 2.67879V12.3212H3.21293V15L5.89093 12.3212H8.03315L12.8534 7.49958V0H2.678ZM11.7818 6.96365L9.63961 9.10651H7.49737L5.62303 10.9814V9.10651H3.21293V1.071H11.7818V6.96365Z" />
      <path className="path" clipRule="evenodd" fillRule="evenodd" fill= "none" d="M10.1742 2.94653H9.10352V6.1604H10.1742V2.94653Z" />
      <path className="path" clipRule="evenodd" fillRule="evenodd" fill= "none" d="M7.22889 2.94653H6.1582V6.1604H7.22889V2.94653Z" />
    </svg>
  );
};
