/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const HeroiconsMiniPhoneArrowDownLeft = ({ className }) => {
  return (
    <svg
      className={`heroicons-mini-phone-arrow-down-left ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M3.5 2C2.67157 2 2 2.67157 2 3.5V5C2 6.14856 2.14913 7.26341 2.42949 8.32576C3.61908 12.8334 7.16665 16.3809 11.6742 17.5705C12.7366 17.8509 13.8514 18 15 18H16.5C17.3284 18 18 17.3284 18 16.5V15.3516C18 14.6486 17.5117 14.0399 16.8254 13.8873L13.6024 13.1711C12.8276 12.999 12.0528 13.4602 11.8348 14.2233L11.5682 15.1561C11.4509 15.5669 11.0134 15.7989 10.6184 15.636C7.79126 14.47 5.53001 12.2087 4.36396 9.38159C4.20107 8.98665 4.4331 8.54913 4.84388 8.43176L5.77667 8.16525C6.53984 7.9472 7.00105 7.17238 6.82887 6.39757L6.11265 3.1746C5.96014 2.4883 5.35142 2 4.64837 2H3.5Z"
        fill="#7041F1"
      />
      <path
        className="path"
        d="M16.7197 2.21967C17.0126 1.92678 17.4874 1.92678 17.7803 2.21967C18.0732 2.51256 18.0732 2.98744 17.7803 3.28033L14.5607 6.5L17.25 6.5C17.6642 6.5 18 6.83579 18 7.25C18 7.66421 17.6642 8 17.25 8L12.75 8C12.3358 8 12 7.66421 12 7.25V2.75C12 2.33579 12.3358 2 12.75 2C13.1642 2 13.5 2.33579 13.5 2.75V5.43934L16.7197 2.21967Z"
        fill="#7041F1"
      />
    </svg>
  );
};
